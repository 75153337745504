import "./index.css";

const Poster = () => (
  <section class="image-section my-5 d-flex justify-cotent-center align-items-center">
    {/* <div class="image-text">
      <h2>Richard Norton Photorealistic Rendering as real photos</h2>
      <p className='text-xs'>
        Progressively incentivize cooperative systems through technically sound
        functionalities. The credibly productivate seamless data.
      </p>
    </div> */}
  </section>
);

export default Poster;
